import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row g-5 g-xl-8"
}
const _hoisted_2 = { class: "card card-xl-stretch mb-xl-8" }
const _hoisted_3 = { class: "card-body pt-5" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-3" }
const _hoisted_6 = { class: "symbol symbol-40px me-1" }
const _hoisted_7 = { class: "text-dark fw-bold" }
const _hoisted_8 = { class: "col-6" }
const _hoisted_9 = { class: "d-flex flex-column" }
const _hoisted_10 = {
  class: "text-dark text-hover-primary fs-6 fw-bolder m-0 p-0",
  style: {"cursor":"pointer"}
}
const _hoisted_11 = { class: "col-3" }
const _hoisted_12 = { class: "d-flex my-3 ms-9" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  "data-bs-toggle": "tooltip",
  "data-bs-trigger": "hover",
  title: "Like",
  "data-bs-original-title": "Like"
}
const _hoisted_15 = { class: "svg-icon svg-icon-3" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "svg-icon svg-icon-3" }
const _hoisted_18 = {
  key: 1,
  class: "row g-5 g-xl-8"
}
const _hoisted_19 = {
  key: 0,
  class: "row g-5 g-xl-8"
}
const _hoisted_20 = { class: "floating-progress" }
const _hoisted_21 = { class: "percentage-label" }
const _hoisted_22 = { class: "card card-xl-stretch mb-xl-8" }
const _hoisted_23 = { class: "card-header border-0 pt-5" }
const _hoisted_24 = { class: "card-title align-items-start flex-column" }
const _hoisted_25 = { class: "card-label fw-bolder text-dark" }
const _hoisted_26 = { class: "card-body pt-5" }
const _hoisted_27 = { class: "col-3" }
const _hoisted_28 = { class: "symbol symbol-40px me-1" }
const _hoisted_29 = { class: "text-dark fw-bold" }
const _hoisted_30 = { class: "col-6" }
const _hoisted_31 = { class: "d-flex flex-column" }
const _hoisted_32 = {
  class: "text-dark text-hover-primary fs-6 fw-bolder m-0 p-0",
  style: {"cursor":"pointer"}
}
const _hoisted_33 = { class: "col-3" }
const _hoisted_34 = { class: "d-flex my-3 ms-9" }
const _hoisted_35 = ["onClick"]
const _hoisted_36 = {
  "data-bs-toggle": "tooltip",
  "data-bs-trigger": "hover",
  title: "Like",
  "data-bs-original-title": "Like"
}
const _hoisted_37 = { class: "svg-icon svg-icon-3" }
const _hoisted_38 = ["onClick"]
const _hoisted_39 = { class: "svg-icon svg-icon-3" }
const _hoisted_40 = { class: "d-flex flex-stack pt-10" }
const _hoisted_41 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_progress = _resolveComponent("el-progress")!

  return (_ctx.isShowExample)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.minatBakatExampleCache, (item2, index2) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index2,
            class: "col-xl-12"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "card-header border-0 pt-5" }, [
                _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
                  _createElementVNode("span", { class: "card-label fw-bolder text-dark" }, "Contoh Soal")
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: _normalizeClass([{ 'mb-7': _ctx.minatBakatExampleCache.length - 1 !== _ctx.index }, "d-flex align-items-center"])
                  }, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("span", {
                          class: _normalizeClass([`bg-light-${item2.Color}`, "symbol-label"])
                        }, [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(item2.QuestionNumber), 1)
                        ], 2)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("p", _hoisted_10, _toDisplayString(item2.QuestionDescription), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("p", {
                          class: _normalizeClass(["btn btn-icon btn-active-light-primary w-30px h-30px me-3", item2.AnswerContent == '1' ? 'btn-active-light-primary' : '']),
                          style: _normalizeStyle(item2.AnswerContent == '1' ? 'background-color: #7FFFD4' : ''),
                          onClick: ($event: any) => (item2.AnswerContent='1'),
                          "data-bs-toggle": "tooltip",
                          title: "",
                          "data-kt-customer-payment-method": "update",
                          "data-bs-original-title": "Update"
                        }, [
                          _createElementVNode("span", _hoisted_14, [
                            _createElementVNode("span", _hoisted_15, [
                              _createVNode(_component_inline_svg, { src: "media/svg/general/thumbs-up.svg" })
                            ])
                          ])
                        ], 14, _hoisted_13),
                        _createElementVNode("p", {
                          class: _normalizeClass(["btn btn-icon btn-active-light-primary w-30px h-30px me-3", item2.AnswerContent == '0' ? 'btn-active-light-primary' : '']),
                          style: _normalizeStyle(item2.AnswerContent == '0' ? 'background-color: #ffb3db' : ''),
                          onClick: ($event: any) => (item2.AnswerContent='0'),
                          "data-bs-toggle": "tooltip",
                          title: "Dislike",
                          "data-kt-customer-payment-method": "delete",
                          "data-bs-original-title": "Dislike"
                        }, [
                          _createElementVNode("span", _hoisted_17, [
                            _createVNode(_component_inline_svg, { src: "media/svg/general/thumbs-down.svg" })
                          ])
                        ], 14, _hoisted_16)
                      ])
                    ])
                  ], 2)
                ])
              ])
            ])
          ]))
        }), 128)),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-lg btn-primary me-3",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openQuestion()))
        }, "Mulai")
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_18, [
        (_ctx.is_ended == false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_el_progress, {
                  percentage: (_ctx.current_time/_ctx.max_time)*100,
                  type: "dashboard",
                  status: "success"
                }, {
                  default: _withCtx(({}) => [
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "percentage-value" }, null, -1)),
                    _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.formattedTime()), 1)
                  ]),
                  _: 1
                }, 8, ["percentage"])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.minatBakatCache, (item2, index2) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index2,
                  class: "col-xl-4"
                }, [
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("h3", _hoisted_24, [
                        _createElementVNode("span", _hoisted_25, _toDisplayString(item2.TitleGroup), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item2.items, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "row"
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass([{ 'mb-7': item2.items.length - 1 !== index }, "d-flex align-items-center"])
                          }, [
                            _createElementVNode("div", _hoisted_27, [
                              _createElementVNode("div", _hoisted_28, [
                                _createElementVNode("span", {
                                  class: _normalizeClass([`bg-light-${item.Color}`, "symbol-label"])
                                }, [
                                  _createElementVNode("span", _hoisted_29, _toDisplayString(item.QuestionNumber), 1)
                                ], 2)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_30, [
                              _createElementVNode("div", _hoisted_31, [
                                _createElementVNode("p", _hoisted_32, _toDisplayString(item.QuestionDescription), 1)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_33, [
                              _createElementVNode("div", _hoisted_34, [
                                _createElementVNode("p", {
                                  onClick: ($event: any) => (_ctx.onAnswer(item, true)),
                                  class: _normalizeClass(["btn btn-icon w-30px h-30px me-3", item.AnswerContent == '1' ? 'btn-active-light-primary' : '']),
                                  style: _normalizeStyle(item.AnswerContent == '1' ? 'background-color: #7FFFD4' : ''),
                                  "data-bs-toggle": "tooltip",
                                  title: "",
                                  "data-kt-customer-payment-method": "update",
                                  "data-bs-original-title": "Update"
                                }, [
                                  _createElementVNode("span", _hoisted_36, [
                                    _createElementVNode("span", _hoisted_37, [
                                      _createVNode(_component_inline_svg, { src: "media/svg/general/thumbs-up.svg" })
                                    ])
                                  ])
                                ], 14, _hoisted_35),
                                _createElementVNode("p", {
                                  onClick: ($event: any) => (_ctx.onAnswer(item, false)),
                                  class: _normalizeClass(["btn btn-icon w-30px h-30px me-3", item.AnswerContent == '0' ? 'btn-active-light-primary' : '']),
                                  style: _normalizeStyle(item.AnswerContent == '0' ? 'background-color: #ffb3db' : ''),
                                  "data-bs-toggle": "tooltip",
                                  title: "Dislike",
                                  "data-kt-customer-payment-method": "delete",
                                  "data-bs-original-title": "Dislike"
                                }, [
                                  _createElementVNode("span", _hoisted_39, [
                                    _createVNode(_component_inline_svg, { src: "media/svg/general/thumbs-down.svg" })
                                  ])
                                ], 14, _hoisted_38)
                              ])
                            ])
                          ], 2)
                        ]))
                      }), 128))
                    ])
                  ])
                ]))
              }), 128)),
              _createElementVNode("div", _hoisted_40, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mr-2" }, null, -1)),
                _createElementVNode("div", null, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-lg btn-primary me-3",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToEnd()))
                  }, "Selesai")
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_41, _cache[5] || (_cache[5] = [
              _createStaticVNode("<div class=\"row g-5 g-xl-8\" data-v-7a652544><div class=\"row mb-6\" data-v-7a652544><div class=\"col-lg-8\" data-v-7a652544><div class=\"image-input image-input-outline\" data-kt-image-input=\"true\" style=\"background-image:url(media/avatars/blank.png);\" data-v-7a652544><div class=\"image-input-wrapper w-800px h-850px\" style=\"background-image:url(media/misc/ty.jpg);\" data-v-7a652544></div></div></div></div></div>", 1)
            ])))
      ]))
}