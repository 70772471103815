
import { Field } from "vee-validate";
import { defineComponent, onBeforeMount, ref, toRefs, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import ApiService2 from "@/core/services/ApiService2";
import Services from "@/core/services/ArrService";
import Swal from "sweetalert2";
import objectPath from "object-path";
import { string } from "yup/lib/locale";

interface IST_Choice{
  ID: string
  Description: string
}

interface IST_Answer{
  ID: string
}

interface IST_Question_Answer{
  IST_Choice: Array<IST_Choice>
  IST_Order: number
  IST_Question: string
  IST_Question_Id: string
  IST_Answer: Array<IST_Answer>
  
  Count_Down_Interval: any
}

export default defineComponent({
  name: "cfit-question-answering-component",
  components: {
    Field,
  },
  props: {
    participantid: String,
    participanttoken: String,
    typetestid: String,
    currenttime: Number,
    cfitExampleObj: {
        type: Object,
        default: () => ({}),
    },
    answercache: {
        type: Array,
        default: () => ([]),
    },
  },
  setup(props, {emit}) {
    // const { cfitGroupId, cfitGroupType, isExample, cfitGroupName} = toRefs(props);
    const { cfitExampleObj, participantid, participanttoken, answercache, currenttime, typetestid} = toRefs(props);
    // var questionCache = ref([] as Array<IST_Question_Answer>)
    var questionCache = ref([] as Array<any>)
    const ParticipantID = participantid.value
    const ParticipantToken = participanttoken.value
    const TypeTestId = typetestid.value
    const answerCache = answercache.value
    var current_time = ref(0)
    var duration = 5; // Initialize countdown

    watch(currenttime, (newVal, oldVal) => {
      current_time.value = newVal || 0
    });

    onBeforeMount(() => {
      cfitExampleObj.value.avatarImageUrl = new URL(cfitExampleObj.value.QuestionImage, process.env.VUE_APP_API_URL_REAL2).href
      if(cfitExampleObj.value.QuestionID != 'dummy')
        getCfitQuestion()

      console.log("cfitExampleObj", cfitExampleObj)
      console.log("answerCache", answerCache)
      // getIstQuestion(props.cfitGroupId)
      
    });

    function setActive(id, index){
      if(cfitExampleObj.value.QuestionKey == '1'){ 
        const existingAnswerIndex = questionCache.value[index].cfitAnswer.findIndex(answer => answer.ID === id)
        if (existingAnswerIndex !== -1) {
          questionCache.value[index].cfitAnswer = []
        }else{
          questionCache.value[index].cfitAnswer = []
          questionCache.value[index].cfitAnswer.push({ID: id})
        }
      }else { //2 jawaban
        const existingAnswerIndex = questionCache.value[index].cfitAnswer.findIndex(answer => answer.ID === id)
        if (existingAnswerIndex !== -1) {
          questionCache.value[index].cfitAnswer.splice(existingAnswerIndex, 1);
        }else{
          questionCache.value[index].cfitAnswer.push({ID: id}); // Add the new item to the array
          if (questionCache.value[index].cfitAnswer.length > 2) 
            questionCache.value[index].cfitAnswer.splice(0, 1); // Remove the first item if the array exceeds length 2
        }
      }

      questionCache.value[index].current_time = current_time.value
      // Start the countdown
      startCountdown(index);
    }

    function startCountdown(index) {
      clearInterval(questionCache.value[index].Count_Down_Interval); // Clear any existing interval

      questionCache.value[index].Count_Down_Interval = setInterval(() => {
        duration -= 1; // Decrease the countdown
        if (duration <= 0) {
          clearInterval(questionCache.value[index].Count_Down_Interval); // Clear any existing interval
          // You can call a function here when the countdown finishes
          // console.log("Countdown finished!", questionCache.value[index]);
          
          saveForm(questionCache.value[index])
        }
      }, 1000); // Update every second
    }

    async function saveForm(val){
      val.cfitAnswer = val.cfitAnswer.orderBy(["ID"], "ASC") 

      var saveObj = {}
      saveObj = Object.assign({}, val)
      saveObj['AnswerId'] = val.answerID
      saveObj['QuestionId'] = val.QuestionID
      saveObj['AnswerContent'] = val.cfitAnswer.map(item => item.ID).join(',')
      saveObj['ParticipantId'] = ParticipantID
      saveObj['Token'] = ParticipantToken
      saveObj['RemainingTime'] =  val.current_time//remainingTime.value
      saveObj['TypeTestId'] = TypeTestId

      await Services.PostData(ApiService2, `process/participant/answer-cfit/saveupdateanswer/`, saveObj, response=>{
        // val.AnswerContent = answer
      }, err =>{
        console.log(err)
      })
    };

    function getClassBox(desc){
      var result = 'col-2'

      // var result = 'col-6'
      // if(desc.length == 1)
        // result = 'col-1'

      return result
    }

    function getActive(id, index){
      var result = ''
      var Cfit_Answer_List = questionCache.value[index].cfitAnswer
      for(let i=0; i<Cfit_Answer_List.length; i++){
          if(Cfit_Answer_List[i].ID == id)
             result = 'active'
      }
      return result
    }

    async function getCfitQuestion(){
      questionCache.value = []
      await Services.GetData(ApiService2, `master/questions/masterid/options/group`, cfitExampleObj.value.TypeTestID + "/" +1+ "/" + cfitExampleObj.value.Group, response=>{
        for (var i = 0; i < response.data.length; i++) {
          response.data[i].avatarImageUrl = new URL(response.data[i].QuestionImage, process.env.VUE_APP_API_URL_REAL2).href
          response.data[i].noUrut = parseInt(response.data[i].QuestionNumber)
          response.data[i].cfitChoice = []
          for (var ii = 65; ii <= response.data[i].MaxAnswersOpt.charCodeAt(0); ii++) {
            response.data[i].cfitChoice.push({ID: String.fromCharCode(ii)});
          }
          response.data[i].answerID = ""
          response.data[i].cfitAnswer = []
          let objResult = answerCache.findFirstUsingEquality((obj)=>{
            return obj.QuestionID == response.data[i].QuestionID
          })
          if(objResult != undefined){
            response.data[i].answerID = objResult.AnswerID
            response.data[i].cfitAnswer = objResult.AnswerContent ? objResult.AnswerContent.split(',').map(item => ({ ID: item })) : []
          }

          // avatarFile.value = await convertUrlToFile(avatarImageUrl.value, props.itemsupdate.QuestionImage)
          // questionCache.value[i].avatarImageUrl= `${ApiService.getUrl()}/IST/storage/${item.IST_Question}`,
        } 
        questionCache.value = response.data.orderBy(["noUrut"], "ASC") 
        console.log("questionCache.value", questionCache.value)
      }, err =>{
        err
      })
    }

    function formMulai(){
      // isExample.value = false
      cfitExampleObj.value['isExample'] = false
      emit("clickExitExample", true);
    }

    const formSubmit = () => {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        confirmButtonText: "Ok, got it!",
      }).then(() => {
        window.location.reload();
      });
    };

    return {
      questionCache,
      setActive,
      getActive,
      startCountdown,
      saveForm,
      formMulai,
      formSubmit,
      getClassBox,
    };
  },
});
