
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";

export default defineComponent({
  name: "kraepelin-mrk",
  components: {
  },
  setup() {
    const iframeUrl = process.env.VUE_APP_API_URL_IFRAME + "/GO/Kraepelin/Participants";
    onMounted(() => {
      setCurrentPageBreadcrumbs("KRAEPELIN", [])
    })

    return {
        iframeUrl
    };
  },
});
