
import { defineComponent, onMounted, ref } from "vue";
import CfitGroupComponent from "@/views/psy/cfit/component/CfitGroupComponent.vue";
import CfitQuestionComponent from "@/views/psy/cfit/component/CfitQuestionComponent.vue";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "cfit-b-adm",
  components: {
    CfitGroupComponent,
    CfitQuestionComponent
  },
  setup() {
    var Cfit_Group_Id = ref("")
    var Cfit_Group_Type = ref("")
    const TypeTestID = process.env.VUE_APP_TYPE_TEST_ID_CFIT_2

    onMounted(() => {
      setCurrentPageBreadcrumbs("CFIT B", [])
    });

    function itemCfit(val) {
      Cfit_Group_Id.value = val.Group
      Cfit_Group_Type.value = TypeTestID
    }

    return {
      itemCfit,
      Cfit_Group_Id,
      Cfit_Group_Type,
      TypeTestID,
    };
  },
});
