
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";
import ApiService from "@/core/services/ApiService";
import { fa } from "element-plus/es/locale";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "papi-kostick-usr2",
  components: {
  },
  setup() {
     const route = useRoute(); // Get the current route

    // Access the route parameters
    const idhref = ref(route.params.id); // This is the "3" from the URL
    const tokhref = ref(route.params.token); // This is the "ppp4" from the URL

    var papiKostickCache = ref([] as Array<any>)
    var papiKostickExampleCache = ref([] as Array<any>)
    const TypeTestID = process.env.VUE_APP_TYPE_TEST_ID_PAPI_KOSTICK
    // const ParticipantID = process.env.VUE_APP_TYPE_TEST_ID_USER
    // const ParticipantToken = process.env.VUE_APP_TYPE_TEST_TOKEN_USER_PAPI_KOSTICK
    const ParticipantID = idhref.value
    const ParticipantToken = tokhref.value
    var historyCache = ref([] as Array<any>)
    var answerCache = ref([] as Array<any>)
    var remainingTime = ref(0)
    var max_time = ref(0)
    var current_time = ref(0)
    var is_ended = ref(false)
    var isShowExample = ref(true)
    var timerInterval = ref(0)
    var timerRunning = ref(false)

    onMounted(() => {
      setCurrentPageBreadcrumbs("PAPI KOSTICK", []);
      loadData()
    });

    watch(remainingTime, async (newVal, oldVal) => {
      current_time.value = newVal;
      // Reset progress bar jika waktu telah habis
      if (newVal == 0) {
        clearInterval(timerInterval.value);
        is_ended.value = true
        await updateParticipantHistory(newVal)
      }
    });

    function startTimer() {
      if (!timerRunning.value && remainingTime.value > 0) { // Tambahkan pengecekan timerRunning
        timerRunning.value = true; // Set timerRunning menjadi true
        // Set interval untuk mengurangi remainingTime setiap detik
        timerInterval.value = setInterval(() => {
          remainingTime.value--;
        }, 1000);
      }
    }

    function formattedTime() {
      const minutes = Math.floor(current_time.value / 60);
      const seconds = current_time.value % 60;
      const result = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
      return result; // Format as mm:ss
    }

    async function loadData() {
      Promise.all([await getHistory(), await getAnswer(), await getPapiKostick()]).then((values) => {
         compareQuestionAndAnswer()
         max_time.value = findMaxTime()
         current_time.value = findCurrentTime()
         is_ended.value = checkEnded()
      });
    }

    async function goToEnd(){
        is_ended.value = true
        await updateParticipantHistory(0)
    }

    async function updateParticipantHistory(val){
      var saveObj = {}
      saveObj['ParticipantId'] = ParticipantID
      saveObj['Session'] = ParticipantToken
      saveObj['Times'] = val
      saveObj['TypeTestId'] = TypeTestID

      await Services.PostData(ApiService2, `process/participant_history/updatetimes/`, saveObj, async response=>{
        // console.log(response)
        if(val == 0){
          const mydatat = {
            Trigger: 'U',
            Route: 'UpdateToken',
            Token: ParticipantToken,
            Remaining_Time: 0,
            Status: 'DONE',
            PID:'PAPIKOSTICK',
          };
          await Services.PostDataXWWW(ApiService, "USER", mydatat, response => {
            window.location.href = process.env.VUE_APP_API_URL_IFRAME + "/GO/Homepage"
          }, err => {
            console.error(err);
          });
        }
        
      }, err =>{
        console.log(err)
      })
    }

    function findCurrentTime(){
      var result = -1

      for(var i=0; i<historyCache.value.length; i++){
        result = historyCache.value[i].Times
      }
      return result
    }

    function findMaxTime(){
      var result = 0
      result =  papiKostickExampleCache.value[0].items[0]['Times'] * 60
      return result
    }

    function checkEnded(){
        remainingTime.value = current_time.value
        if(current_time.value == -1)
          remainingTime.value = max_time.value

        var result = false
        if(current_time.value == 0)
          result = true

        return result
    }

    function compareQuestionAndAnswer(){
      for(var i=0; i<answerCache.value.length; i++){
        var foundObj = papiKostickCache.value.findFirst(answerCache.value[i]['QuestionID'], 'QuestionID')
        if(foundObj != undefined){
          foundObj['AnswerContent'] = answerCache.value[i].AnswerContent
          foundObj['AnswerID'] = answerCache.value[i].AnswerID
          foundObj['isActive'] = true
        }
      }

      papiKostickExampleCache.value = papiKostickExampleCache.value.groupBy(["Numb"])
      papiKostickExampleCache.value = papiKostickExampleCache.value.orderBy(["Numb"], "ASC")

      papiKostickCache.value = papiKostickCache.value.groupBy(["Numb"])
      papiKostickCache.value = papiKostickCache.value.orderBy(["Numb"], "ASC")
    }

    async function getHistory(){
      historyCache.value = []
      await Services.GetData(ApiService2, `process/participant_history/getbytokenandparticipant`, ParticipantID+ "/" +ParticipantToken+ "/" +TypeTestID, response=>{
          historyCache.value = response.data
      }, err =>{
        err
      })
    }

    async function getAnswer(){
      answerCache.value = []
      await Services.GetData(ApiService2, `process/participant/answer-papi-kostick/getbytokenandparticipant`, ParticipantID+ "/" +ParticipantToken, response=>{
          answerCache.value = response.data
      }, err =>{
        err
      })
    }

    async function getPapiKostick(){
      papiKostickCache.value = []
      papiKostickExampleCache.value = []
      await Services.GetData(ApiService2, `master/questions-papi-kostick-2/masterid`, TypeTestID, response=>{
        for (var i = 0; i < response.data.length; i++) {
          response.data[i].Numb = Number(response.data[i].QuestionNumber)
          response.data[i].isActive = false
          if(response.data[i]['IsExample'] == 1){           
            papiKostickExampleCache.value.push(response.data[i])
          }else{
            papiKostickCache.value.push(response.data[i])
          }
        }
      }, err =>{
        err
      })
    }

    async function handleChange(val, isExample){
      var saveObj = {}
      var foundObj = {}
      if(isExample){
        foundObj = papiKostickExampleCache.value.findFirst(val.Numb, 'Numb')
      }else{
        foundObj = papiKostickCache.value.findFirst(val.Numb, 'Numb')
      }
      foundObj['items'].forEach(function(item) {
        item.isActive = false // Add address dynamically based on name
        if(item.QuestionID == val.QuestionID)
         val.isActive = true
      });

      if(!isExample){
        saveObj = Object.assign({}, val)
        saveObj['AnswerId'] = val.AnswerID
        saveObj['QuestionId'] = val.QuestionID
        saveObj['AnswerContent'] = val.Numb
        saveObj['ParticipantId'] = ParticipantID
        saveObj['Token'] = ParticipantToken
        saveObj['RemainingTime'] = remainingTime.value
        saveObj['TypeTestId'] = TypeTestID

        await Services.PostData(ApiService2, `process/participant/answer-papi-kostick/saveupdateanswer/`, saveObj, response=>{
          // val.AnswerContent = answer
        }, err =>{
          console.log(err)
        })
      }
    }

    function openQuestion(){
      isShowExample.value = false
      startTimer()
    }

    return {
      TypeTestID,
      papiKostickCache,
      papiKostickExampleCache,
      isShowExample,
      handleChange,
      openQuestion,
      goToEnd,
      formattedTime,
      max_time,
      current_time,
      is_ended,
    };
  },
});
