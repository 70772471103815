
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import html2pdf from "html2pdf.js";

export default defineComponent({
  name: "cfit-mrk",
  components: {
  },
  setup() {
    var participantCache = ref([] as Array<any>)
    var graphCache = ref([] as Array<any>)
    var resultCache =  ref([] as Array<any>)
    const TypeTestID = process.env.VUE_APP_TYPE_TEST_ID_CFIT
    const pdfContent = ref(null);
    let isDialog = ref(false)
    let itemsDialog = ref({})
    var notesCache = ref("")
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-warning");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");
    const series = ref([] as Array<any>);
    const options = ref({
      chart: {
        fontFamily: "inherit",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["30%"],
          endingShape: "rounded",
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [] as Array<any>,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("CFIT A", [])
      loadData()
    })

    async function loadData(){
      participantCache.value = []
      await Services.GetData(ApiService2, `result/tipetest`, TypeTestID, response=>{
          participantCache.value = response.data
      }, err =>{
        err
      })
    }

    async function openModal(item){
      Promise.all([await getResult(item)]).then((values) => {
        // mappingChart()
        isDialog.value = true
      });
    }

    async function getResult(item){
      resultCache.value = []
      await Services.GetData(ApiService2, `result/tokenandpartisipantcfit`, item.session+'/'+ item.participant_id+'/'+TypeTestID, response=>{
          itemsDialog.value = item
          resultCache.value = response.data
          console.log("response.data", response.data)
      }, err =>{
        err
      })
    }

    function mappingChart(){
      series.value = []       
      options.value = {
        ...options.value, // Preserve the rest of the options
        xaxis: {
          ...options.value.xaxis,
          categories: [] // Clear the categories here
        }
      };
      var dataNew : any[] = [];
      for (var i = 0; i < graphCache.value.length; i++) {
        var factor = graphCache.value[i].value.toUpperCase()
        options.value.xaxis.categories.push('Faktor: '+ factor)
        var tot = graphCache.value[i].total_val
        dataNew.push(tot)
      }
      var obj = {name: "Total", data:dataNew}
      series.value.push(obj)
    }

    function closeModal(){
      isDialog.value = false
      itemsDialog.value = {}
    }

    // Generate PDF from modal content
    function generatePdf(item) {
      const element =  pdfContent.value; // Access the DOM element via the ref
      const options = {
        filename: `Laporan_Cfit-${item.name}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      html2pdf().from(element).set(options).save();
    }

    return {
      participantCache,
      graphCache,
      openModal,
      closeModal,
      isDialog,
      itemsDialog,
      options,
      series,
      generatePdf,
      pdfContent,
      resultCache,
    };
  },
});
