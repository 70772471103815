const DocMenuConfig = [
  {
    heading: "USER",
    // route: "/test",
    pages: [
      // {
      //   heading: "PAPI KOSTICK",
      //   route: "/test/papi-kostick-2",
      //   svgIcon: "media/icons/duotune/finance/fin006.svg",
      //   fontIcon: "bi-printer",
      // },
      // {
      //   heading: "IST",
      //   route: "/test/ist-2",
      //   svgIcon: "media/icons/duotune/finance/fin006.svg",
      //   fontIcon: "bi-printer",
      // },
    ],
  },
];

export default DocMenuConfig;
