import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "floating-progress" }
const _hoisted_2 = { class: "percentage-label" }
const _hoisted_3 = {
  class: "stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid",
  ref: "verticalWizardRef"
}
const _hoisted_4 = { class: "d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-150px w-xxl-400px me-9" }
const _hoisted_5 = { class: "px-2 px-lg-50 px-xxl-15 py-20" }
const _hoisted_6 = { class: "stepper-nav" }
const _hoisted_7 = { class: "stepper-icon w-40px h-40px" }
const _hoisted_8 = { class: "stepper-number" }
const _hoisted_9 = { class: "stepper-label" }
const _hoisted_10 = { class: "stepper-title" }
const _hoisted_11 = { class: "stepper-desc fw-bold" }
const _hoisted_12 = { class: "d-flex flex-row-fluid flex-center bg-white rounded" }
const _hoisted_13 = { class: "d-flex flex-stack pt-10" }
const _hoisted_14 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_CfitQuestionAnsweringComponent2 = _resolveComponent("CfitQuestionAnsweringComponent2")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_progress, {
        percentage: (_ctx.current_time/_ctx.max_time)*100,
        type: "dashboard",
        status: "success"
      }, {
        default: _withCtx(({}) => [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "percentage-value" }, null, -1)),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.formattedTime()), 1)
        ]),
        _: 1
      }, 8, ["percentage"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cfitExampleCache, (item, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass([{ 'current': _ctx.currentStepIndex === index, 'completed': item.completed }, "stepper-item"]),
                "data-kt-stepper-element": "nav"
              }, [
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "stepper-line w-40px" }, null, -1)),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("i", {
                    class: _normalizeClass(item.completed ? 'stepper-check fas fa-check' : '')
                  }, null, 2),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(item.Group), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("h3", _hoisted_10, _toDisplayString(item.QuestionNumber), 1),
                  _createElementVNode("div", _hoisted_11, _toDisplayString(item.Times) + " Menit", 1)
                ])
              ], 2)), [
                [_vShow, item.QuestionID != 'dummy']
              ])
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("form", {
          class: "py-20 w-100 w-xl-700px px-9",
          onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleStep && _ctx.handleStep(...args)), ["prevent"]))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cfitExampleCache, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass({ 'current': _ctx.currentStepIndex === index }),
              "data-kt-stepper-element": "content"
            }, [
              (_ctx.currentStepIndex === index)
                ? (_openBlock(), _createBlock(_component_CfitQuestionAnsweringComponent2, {
                    key: 0,
                    currenttime: _ctx.current_time,
                    answercache: _ctx.answerCache,
                    participantid: _ctx.ParticipantID,
                    participanttoken: _ctx.ParticipantToken,
                    typetestid: _ctx.TypeTestID,
                    onClickExitExample: _ctx.changeShowButton,
                    "cfit-example-obj": item
                  }, null, 8, ["currenttime", "answercache", "participantid", "participanttoken", "typetestid", "onClickExitExample", "cfit-example-obj"]))
                : _createCommentVNode("", true)
            ], 2))
          }), 128)),
          _createElementVNode("div", _hoisted_13, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mr-2" }, null, -1)),
            (_ctx.isShowButton)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[3] || (_cache[3] = [
                  _createElementVNode("button", {
                    type: "submit",
                    class: "btn btn-lg btn-primary"
                  }, "Lanjut", -1)
                ])))
              : _createCommentVNode("", true)
          ])
        ], 32)
      ])
    ], 512)
  ], 64))
}