import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-100"
}
const _hoisted_2 = { class: "row mb-6" }
const _hoisted_3 = { class: "col-lg-8" }
const _hoisted_4 = {
  class: "image-input image-input-outline",
  "data-kt-image-input": "true",
  style: {"background-image":"url(media/avatars/blank.png)"}
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "d-flex flex-stack pt-10" }
const _hoisted_7 = {
  key: 1,
  class: "w-100"
}
const _hoisted_8 = { class: "pb-10 pb-lg-15" }
const _hoisted_9 = { class: "fw-bolder text-dark" }
const _hoisted_10 = { class: "row mb-6" }
const _hoisted_11 = { class: "col-lg-8" }
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  class: "row mb-2 border-bottom border-gray-300",
  "data-kt-buttons": "true"
}
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "fw-bolder fs-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_ctx.cfitExampleObj.isExample)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                src: _ctx.cfitExampleObj.avatarImageUrl || 'media/avatars/blank.png',
                alt: "Image Preview",
                style: { maxWidth: '700px', maxHeight: '700px', objectFit: 'contain' }
              }, null, 8, _hoisted_5)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mr-2" }, null, -1)),
          _createElementVNode("div", null, [
            (_ctx.cfitExampleObj.QuestionID == 'dummy')
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn btn-lg btn-primary me-3",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.formSubmit && _ctx.formSubmit(...args)))
                }, "Selesai"))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  type: "button",
                  class: "btn btn-lg btn-primary me-3",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.formMulai && _ctx.formMulai(...args)))
                }, "Mulai"))
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionCache, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("h2", _hoisted_9, "No. " + _toDisplayString(item.QuestionNumber), 1),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("img", {
                      src: item.avatarImageUrl || 'media/avatars/blank.png',
                      alt: "Image Preview",
                      style: { maxWidth: '500px', maxHeight: '500px', objectFit: 'contain' }
                    }, null, 8, _hoisted_12)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.cfitChoice, (item2, index2) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index2,
                    class: _normalizeClass(_ctx.getClassBox(item2.ID)),
                    style: {"margin-bottom":"20px"}
                  }, [
                    _createElementVNode("label", {
                      class: _normalizeClass(["btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4", _ctx.getActive(item2.ID, index)])
                    }, [
                      _createVNode(_component_Field, {
                        type: "radio",
                        class: "btn-check",
                        name: item.Group+'-'+item.QuestionID+'-'+item2.ID,
                        value: item2.ID,
                        onClick: ($event: any) => (_ctx.setActive(item2.ID, index))
                      }, null, 8, ["name", "value", "onClick"]),
                      _createElementVNode("span", _hoisted_15, _toDisplayString(item2.ID), 1)
                    ], 2)
                  ], 2))
                }), 128))
              ])
            ])
          ], 64))
        }), 128))
      ]))
}