
import { computed, defineComponent, onMounted, ref, nextTick, watch} from "vue";
import Swal from "sweetalert2";
import { useForm } from "vee-validate";
import CfitQuestionAnsweringComponent2 from "@/views/psy/cfit2/component/CfitQuestionAnsweringComponent2.vue";
import ApiService from "@/core/services/ApiService";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "cfit-b-usr2",
  components: {
    CfitQuestionAnsweringComponent2,
  },

  setup() {
    const route = useRoute(); // Get the current route

    // Access the route parameters
    const idhref = ref(route.params.id); // This is the "3" from the URL
    const tokhref = ref(route.params.token); // This is the "ppp4" from the URL


    const verticalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const cfitExampleCache = ref([] as Array<any>)
    var historyCache = ref([] as Array<any>)
    var answerCache = ref([] as Array<any>)
    var Question1Cache = ref([] as Array<any>)
    const TypeTestID = process.env.VUE_APP_TYPE_TEST_ID_CFIT_2
    // const ParticipantID = process.env.VUE_APP_TYPE_TEST_ID_USER
    // const ParticipantToken = process.env.VUE_APP_TYPE_TEST_TOKEN_USER_CFIT_2
    const ParticipantID = idhref.value
    const ParticipantToken = tokhref.value
    var isShowButton = ref(false)
    var max_time = ref(0)
    var current_time = ref(0)
    var remainingTime = ref(0)
    var timerInterval = ref(0)
    var timerRunning = ref(false)

    const { handleSubmit } = useForm();

    const handleStep = handleSubmit(() => {
      // Mark the current step as completed
      cfitExampleCache.value[currentStepIndex.value].completed = true;
      isShowButton.value = false
      // Move to the next step
      if (currentStepIndex.value < cfitExampleCache.value.length - 1) {
        currentStepIndex.value++;
      }

      if(cfitExampleCache.value[currentStepIndex.value].QuestionID == 'dummy') 
        updateParticipantHistory()
    });

    const previousStep = () => {
      if (currentStepIndex.value > 0) {
        currentStepIndex.value--;
      }
    };

    function changeShowButton(val) {
      isShowButton.value = true
      cfitExampleCache.value[currentStepIndex.value].isExample = false;
    }

    function initDuration(){
      timerRunning.value = false;
      clearInterval(timerInterval.value);  
      max_time.value = cfitExampleCache.value[currentStepIndex.value].Times * 60
      current_time.value = max_time.value
      remainingTime.value = current_time.value
      if(current_time.value == -1)
        remainingTime.value = max_time.value
    }

    const formSubmit = () => {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        confirmButtonText: "Ok, got it!",
      }).then(() => {
        window.location.reload();
      });
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("CFIT B", []);
      // Fetch your groups or any initial data here
      Promise.all([await getHistory(), await getAnswer(), await getCfitGroup()]).then(async(values) => {
         await getQuestion1()
      });
    });

    async function getQuestion1(){
      Question1Cache.value = []
      if(historyCache.value.length == 0)
        return 

      var questionId = ""
      questionId = historyCache.value[0].QuestionId
      await Services.GetData(ApiService2, `master/questions/byid2`, questionId, response=>{
          Question1Cache.value = response.data

          currentStepIndex.value = cfitExampleCache.value.findIndex(item => item.Group == Question1Cache.value[0].Group)
          current_time.value = historyCache.value[0].Times
          remainingTime.value = current_time.value

          // console.log("currentStepIndex.value", currentStepIndex.value)
          // console.log("currentStepIndex.value", currentStepIndex.value)
          if((historyCache.value[0].Times == 0) && (currentStepIndex.value == (cfitExampleCache.value.length - 1)))
            currentStepIndex.value++

          for(var i=0; i<=currentStepIndex.value; i++){
            cfitExampleCache.value[i].completed = true;
          }          
      }, err =>{
        err
      })
    }

     async function getHistory(){
      historyCache.value = []
      await Services.GetData(ApiService2, `process/participant_history/getbytokenandparticipant`, ParticipantID+ "/" +ParticipantToken+ "/" +TypeTestID, response=>{
          historyCache.value = response.data
          console.log("historyCache.value", historyCache.value)
      }, err =>{
        err
      })
    }

    async function getAnswer(){
      answerCache.value = []
      await Services.GetData(ApiService2, `process/participant/answer-cfit/getbytokenandparticipant`, ParticipantID+ "/" +ParticipantToken, response=>{
          answerCache.value = response.data
          console.log("answerCache.value", answerCache.value)
      }, err =>{
        err
      })
    }

    async function getCfitGroup(){
      cfitExampleCache.value = []
      await Services.GetData(ApiService2, `master/questions/masterid/options`, TypeTestID+ "/" +2, response=>{
        cfitExampleCache.value = response.data
        cfitExampleCache.value = cfitExampleCache.value.orderBy(["Group"], "ASC")

        cfitExampleCache.value = cfitExampleCache.value.map(item => ({ ...item, completed: false, isExample:true, }));
        cfitExampleCache.value.push({
          completed: false, 
          isExample: true,
          QuestionID: "dummy",
          SerialNumber: 0,
          QuestionNumber:  0,
          QuestionImage:  "public/cfit/999.jpg",
          QuestionDescription : "",
          QuestionKey: "",
          QuestionOptions: "",
          TypeTestID: "",
          Times: 0,
          MaxAnswersOpt: "",
          Group: "ZZZZ",
        })
        console.log("cfitExampleCache.value", cfitExampleCache.value)
        initDuration()
      }, err =>{
        err
      })
    }

    //  function compareQuestionAndAnswer(){
    //   for(var i=0; i<answerCache.value.length; i++){
    //     var foundObj = modalitasBelajarCache.value.findFirst(answerCache.value[i]['QuestionID'], 'QuestionID')
    //     if(foundObj != undefined){
    //       foundObj['AnswerContent'] = answerCache.value[i].AnswerContent
    //       foundObj['AnswerID'] = answerCache.value[i].AnswerID
    //     }
    //   }

    //   modalitasBelajarCache.value = modalitasBelajarCache.value.orderBy(["Numb"], "ASC")
    // }

    function goingUp(){
      nextTick(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top
      });
    }

    function formattedTime() {
      const minutes = Math.floor(current_time.value / 60);
      const seconds = current_time.value % 60;
      const result = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
      return result; // Format as mm:ss
    }

    watch(remainingTime, (newVal, oldVal) => {
      current_time.value = newVal;
      // Reset progress bar jika waktu telah habis
      if (newVal == 0 && isShowButton.value) {
        clearInterval(timerInterval.value);
        handleStep()
        // is_ended.value = true
        // await updateParticipantHistory(newVal)
      }
    });

    watch(isShowButton, (newVal, oldVal) => {
      //false mean example, true mean main question
      goingUp()
      if(newVal){
        if(!timerRunning.value && remainingTime.value > 0){
          startTimer()
        }else{
          handleStep()
        }
      }else{
        initDuration()
      }
    });

    function startTimer() {
      if (!timerRunning.value && remainingTime.value > 0) { // Tambahkan pengecekan timerRunning
        timerRunning.value = true; // Set timerRunning menjadi true
        // Set interval untuk mengurangi remainingTime setiap detik
        timerInterval.value = setInterval(() => {
          remainingTime.value--;
        }, 1000);
      }
    }

    async function updateParticipantHistory(){
      var saveObj = {}
      saveObj['ParticipantId'] = ParticipantID
      saveObj['Session'] = ParticipantToken
      saveObj['Times'] = 0
      saveObj['TypeTestId'] = TypeTestID

      await Services.PostData(ApiService2, `process/participant_history/updatetimes/`, saveObj, async response=>{
        // console.log(response)
         const mydatat = {
            Trigger: 'U',
            Route: 'UpdateToken',
            Token: ParticipantToken,
            Remaining_Time: 0,
            Status: 'DONE',
            PID:'CFIT_B',
          };
          await Services.PostDataXWWW(ApiService, "USER", mydatat, response => {
            window.location.href = process.env.VUE_APP_API_URL_IFRAME + "/GO/Homepage"
          }, err => {
            console.error(err);
          });
      }, err =>{
        console.log(err)
      })
    }

    return {
      verticalWizardRef,
      currentStepIndex,
      cfitExampleCache,
      handleStep,
      previousStep,
      formSubmit,
      isShowButton,
      changeShowButton,
      current_time,
      max_time,
      formattedTime,
      ParticipantID,
      ParticipantToken,
      TypeTestID,
      Question1Cache,
      answerCache,
    };
  },
});
