
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";

export default defineComponent({
  name: "modalitas-belajar-usr",
  components: {
  },
  setup() {
    var modalitasBelajarCache = ref([] as Array<any>)
    var modalitasBelajarExampleCache = ref([] as Array<any>)
    var historyCache = ref([] as Array<any>)
    var answerCache = ref([] as Array<any>)
    var keyList = ref([] as Array<any>)
    const TypeTestID = process.env.VUE_APP_TYPE_TEST_ID_MODALITAS_BELAJAR
    const ParticipantID = process.env.VUE_APP_TYPE_TEST_ID_USER
    const ParticipantToken = process.env.VUE_APP_TYPE_TEST_TOKEN_USER_MODALITAS_BELAJAR
    var remainingTime = ref(0)
    var max_time = ref(0)
    var current_time = ref(0)
    var is_ended = ref(false)
    var isShowExample = ref(true)
    var timerInterval = ref(0)
    var timerRunning = ref(false)

    onMounted(() => {
      setCurrentPageBreadcrumbs("MODALITAS BELAJAR", []);
      loadData()
    });

    watch(remainingTime, async (newVal, oldVal) => {
      current_time.value = newVal;
      // Reset progress bar jika waktu telah habis
      if (newVal == 0) {
        clearInterval(timerInterval.value);
        is_ended.value = true
        await updateParticipantHistory(newVal)
      }
    });

    function startTimer() {
      if (!timerRunning.value && remainingTime.value > 0) { // Tambahkan pengecekan timerRunning
        timerRunning.value = true; // Set timerRunning menjadi true
        // Set interval untuk mengurangi remainingTime setiap detik
        timerInterval.value = setInterval(() => {
          remainingTime.value--;
        }, 1000);
      }
    }

    function formattedTime() {
      const minutes = Math.floor(current_time.value / 60);
      const seconds = current_time.value % 60;
      const result = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
      return result; // Format as mm:ss
    }

    async function loadData() {
      Promise.all([await getHistory(), await getAnswer(), await getModalitasBelajar()]).then((values) => {
        compareQuestionAndAnswer()
        max_time.value = findMaxTime()
        current_time.value = findCurrentTime()
        is_ended.value = checkEnded()
      });
    }

    function findCurrentTime(){
      var result = -1

      for(var i=0; i<historyCache.value.length; i++){
        result = historyCache.value[i].Times
      }
      return result
    }

    function findMaxTime(){
      var result = 0
      result =  modalitasBelajarExampleCache.value[0]['Times'] * 60
      return result
    }

    function checkEnded(){
        remainingTime.value = current_time.value
        if(current_time.value == -1)
          remainingTime.value = max_time.value

        var result = false
        if(current_time.value == 0)
          result = true

        return result
    }

    async function goToEnd(){
        is_ended.value = true
        await updateParticipantHistory(0)
    }

    function compareQuestionAndAnswer(){
      for(var i=0; i<answerCache.value.length; i++){
        var foundObj = modalitasBelajarCache.value.findFirst(answerCache.value[i]['QuestionID'], 'QuestionID')
        if(foundObj != undefined){
          foundObj['AnswerContent'] = answerCache.value[i].AnswerContent
          foundObj['AnswerID'] = answerCache.value[i].AnswerID
        }
      }

      modalitasBelajarCache.value = modalitasBelajarCache.value.orderBy(["Numb"], "ASC")
    }

    function getRandomColor() {
      var colorList = ["success", "warning", "primary", "danger", "info"];

      return colorList[Math.floor(Math.random() * 5)]
    }

    async function getHistory(){
      historyCache.value = []
      await Services.GetData(ApiService2, `process/participant_history/getbytokenandparticipant`, ParticipantID+ "/" +ParticipantToken+ "/" +TypeTestID, response=>{
          historyCache.value = response.data
      }, err =>{
        err
      })
    }

    async function getAnswer(){
      answerCache.value = []
      await Services.GetData(ApiService2, `process/participant/answer-modalitas-belajar/getbytokenandparticipant`, ParticipantID+ "/" +ParticipantToken, response=>{
          answerCache.value = response.data
      }, err =>{
        err
      })
    }

    async function getModalitasBelajar(){
      modalitasBelajarCache.value = []
      modalitasBelajarExampleCache.value = []
      await Services.GetData(ApiService2, `master/questions-modalitas-belajar/masterid`, TypeTestID, response=>{
        for (var i = 0; i < response.data.length; i++) {
          response.data[i].Color = getRandomColor()
          response.data[i].Numb = Number(response.data[i].QuestionNumber)
          response.data[i].AnswerContent = ""
          response.data[i].AnswerID = ""

          if(response.data[i]['IsExample'] == 1){           
            modalitasBelajarExampleCache.value.push(response.data[i])
          }else{
            modalitasBelajarCache.value.push(response.data[i])
          }
        }
        createBox(modalitasBelajarExampleCache.value)
      }, err =>{
        err
      })
    }

    async function updateParticipantHistory(val){
      var saveObj = {}
      saveObj['ParticipantId'] = ParticipantID
      saveObj['Session'] = ParticipantToken
      saveObj['Times'] = val
      saveObj['TypeTestId'] = TypeTestID

      await Services.PostData(ApiService2, `process/participant_history/updatetimes/`, saveObj, response=>{
        // console.log(response)
      }, err =>{
        console.log(err)
      })
    }

    function openQuestion(){
      isShowExample.value = false
      startTimer()
    }

    function formatText(text) {
      // Replace all \r\n and \n with <br> to create line breaks
      return text.replace(/\r?\n/g, '<br>');
    }

    function createBox(data){
      var foundObj = data.find((element) => element.KeyList != "")
      var optionsBoxsString = foundObj.KeyList
      var optionsBoxsArr = optionsBoxsString.split(",")

      keyList.value = optionsBoxsArr
    }

    async function handleRadioChange(val){
      var saveObj = {}
      saveObj = Object.assign({}, val)
      saveObj['AnswerId'] = val.AnswerID
      saveObj['QuestionId'] = val.QuestionID
      saveObj['AnswerContent'] = val.AnswerContent
      saveObj['ParticipantId'] = ParticipantID
      saveObj['Token'] = ParticipantToken
      saveObj['RemainingTime'] = remainingTime.value
      saveObj['TypeTestId'] = TypeTestID

      await Services.PostData(ApiService2, `process/participant/answer-modalitas-belajar/saveupdateanswer/`, saveObj, response=>{
        // val.AnswerContent = answer
      }, err =>{
        console.log(err)
      })
    }

    return {
      TypeTestID,
      ParticipantID,
      ParticipantToken,
      modalitasBelajarCache,
      modalitasBelajarExampleCache,
      historyCache,
      answerCache,
      remainingTime,
      max_time,
      current_time,
      is_ended,
      openQuestion,
      isShowExample,
      formattedTime,
      formatText,
      keyList,
      handleRadioChange,
      goToEnd,
    };
  },
});
